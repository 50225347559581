import React, { ReactNode, useCallback, useContext, useEffect, useMemo } from 'react';

import { openInputGptSubMenu, setCmdPaletteOpen } from '../../../../shared/foreground/cmdPalette';
import {
  addGptOutputToNote,
  generateHighlights,
  generateSummary,
  generateTags,
  Prompt,
  PromptResponseAction,
  PromptReturnType,
  PromptScopeType,
  PromptType,
  useFlatOverrideOrRealPromptScopes,
  useOverrideOrRealPrompt,
} from '../../../../shared/foreground/ghostreader';
import { focusedHighlightIdState, globalState, updateState } from '../../../../shared/foreground/models';
import { useDocument } from '../../../../shared/foreground/stateHooks';
import useFocusedDocument from '../../../../shared/foreground/stateHooks/useFocusedDocument';
import { resetDocumentSummaryGeneration } from '../../../../shared/foreground/stateUpdaters/transientStateUpdaters/documentSummary';
import { createToast } from '../../../../shared/foreground/toasts.platform';
import { FirstClassDocument, FullZustandState, Highlight } from '../../../../shared/types';
import getWords from '../../../../shared/utils/getWords';
import { getActionKey } from '../../utils/getActionKey';
import { PaletteAction } from './Base/PaletteAction';
import { CmdInputContext, PaletteWrapper } from './Base/PaletteWrapper';

const GptPaletteAction = ({
  action,
  focused,
  children,
}: PaletteAction & {
  action: () => void;
  children: ReactNode;
}) => {
  return (
    <PaletteAction focused={focused} action={action}>
      {children}
    </PaletteAction>
  );
};

const setPrompt = (prompt: string, systemPrompt?: string) => {
  updateState(
    (state) => {
      state.gptPrompt = state.gptPrompt ?? {
        selection: '',
        expandedSelection: '',
        surroundingParagraphContents: '',
        systemPrompt: '',
        prompt: '',
      };
      state.gptPrompt.prompt = prompt;
      state.gptPrompt.systemPrompt = systemPrompt;
    },
    { eventName: 'gpt-prompt-manual', userInteraction: 'unknown' },
  );
  return prompt;
};

const INPUT_REGEX = /input\("(.+)"\)/;

const promptAction = (
  prompt: Prompt,
  gptPrompt: FullZustandState['gptPrompt'],
  highlight: Highlight | null,
  document: FirstClassDocument | null,
) => {
  return () => {
    setPrompt(prompt.template, prompt.system);

    // Actions: (input is special, the others just handle actual actions)
    if (prompt.template.match(INPUT_REGEX)) {
      openInputGptSubMenu();
    } else if (prompt.responseAction === PromptResponseAction.AppendToNote) {
      addGptOutputToNote(document, gptPrompt, highlight, prompt.template, prompt.system);
    } else if (prompt.responseAction === PromptResponseAction.UpdateSummary) {
      generateSummary(document, prompt);
    } else {
      if (prompt.returnType === PromptReturnType.Text) {
        // this should never happen
        throw new Error(
          `Return type "text" is not supported for response action: ${prompt.responseAction}`,
        );
      }

      switch (prompt.responseAction) {
        case PromptResponseAction.CreateHighlights:
          generateHighlights(document, prompt);
          break;
        case PromptResponseAction.CreateTags:
          generateTags(document, prompt);
          break;
        default:
          // this should never happen
          throw new Error(`Unknown response action: ${prompt.responseAction}`);
      }
    }
  };
};

// When input("placeholder") is in the prompt, we show this palette
export const InputGptPromptPalette = (): JSX.Element => {
  const highlightId = focusedHighlightIdState(useCallback((state) => state.focusedHighlightId, []));
  const [highlight] = useDocument<Highlight>(highlightId);
  const [doc] = useFocusedDocument();
  const gptPrompt = globalState(useCallback((state) => state.gptPrompt, []));
  const result = useMemo(() => {
    if (!gptPrompt?.prompt) {
      return [null, null];
    }
    return gptPrompt.prompt.match(INPUT_REGEX);
  }, [gptPrompt?.prompt]);

  if (!result?.[0] || !result?.[1]) {
    createToast({
      content: 'No input prompt found',
      category: 'error',
    });
    return <></>;
  }

  const [, placeholder] = result;

  return (
    <PaletteWrapper hasInput title={placeholder}>
      <GptPromptAction highlight={highlight} document={doc} gptPrompt={gptPrompt} />
    </PaletteWrapper>
  );
};

export const DocumentGptPromptPalette = () => {
  const highlightId = focusedHighlightIdState(useCallback((state) => state.focusedHighlightId, []));
  const [highlight] = useDocument<Highlight>(highlightId);
  const [doc, { isFetching }] = useFocusedDocument();
  const gptPrompt = globalState(useCallback((state) => state.gptPrompt, []));
  // TODO: can't remember why this side-effect exists, looks weird
  useEffect(() => {
    resetDocumentSummaryGeneration('unknown');
  }, [doc]);

  useEffect(() => {
    if (isFetching) {
      return;
    }

    if (!doc?.transientData?.content?.length) {
      createToast({
        content: 'Cannot invoke Ghostreader on document without a text layer',
        category: 'error',
      });

      setCmdPaletteOpen(false, { userInteraction: 'unknown' });
    }
  }, [doc, isFetching]);

  const documentPrompts = useFlatOverrideOrRealPromptScopes(
    PromptScopeType.Automatic,
    PromptScopeType.Document,
  );
  const highlightPrompt = useOverrideOrRealPrompt(
    PromptScopeType.DocumentSpecial,
    PromptType.HighlightDocument,
  );

  return (
    <PaletteWrapper title="Choose Prompt">
      {[...documentPrompts, highlightPrompt].map((prompt) => {
        return (
          <GptPaletteAction
            label={prompt.title}
            key={prompt.title}
            focused={false}
            action={promptAction(prompt, gptPrompt, highlight, doc)}
          >
            {prompt.title}
          </GptPaletteAction>
        );
      })}
    </PaletteWrapper>
  );
};

// Palette shown on highlights
export const HighlightGptPromptPalette = (): JSX.Element => {
  const highlightId = focusedHighlightIdState(useCallback((state) => state.focusedHighlightId, []));
  const [highlight] = useDocument<Highlight>(highlightId);
  const [doc] = useFocusedDocument();
  const gptPrompt = globalState(useCallback((state) => state.gptPrompt, []));
  const shortSelection = useMemo(
    () =>
      gptPrompt?.expandedSelection &&
      getWords(gptPrompt.expandedSelection, doc?.language || 'unknown').length <= 4,
    [doc?.language, gptPrompt?.expandedSelection],
  );
  const passagePromptScopes = useFlatOverrideOrRealPromptScopes(PromptScopeType.Passage);
  const wordPromptScopes = useFlatOverrideOrRealPromptScopes(PromptScopeType.Word);

  return (
    <PaletteWrapper title="Choose Prompt">
      {shortSelection &&
        wordPromptScopes.map((prompt: Prompt) => (
          <GptPaletteAction
            label={prompt.title}
            key={prompt.title}
            focused={false}
            action={promptAction(prompt, gptPrompt, highlight, doc)}
          >
            {prompt.title}
          </GptPaletteAction>
        ))}

      {!shortSelection &&
        passagePromptScopes.map((prompt: Prompt) => (
          <GptPaletteAction
            label={prompt.title}
            key={prompt.title}
            focused={false}
            action={promptAction(prompt, gptPrompt, highlight, doc)}
          >
            {prompt.title}
          </GptPaletteAction>
        ))}
    </PaletteWrapper>
  );
};

// Fire the prompt, correcting if it's the Manual or Input palette!
export const GptPromptAction = ({
  highlight,
  gptPrompt,
  document,
  useInput,
}: {
  highlight?: Highlight | null;
  document: FirstClassDocument | null;
  gptPrompt: FullZustandState['gptPrompt'];
  useInput?: boolean;
}): JSX.Element => {
  const { input: inputPrompt } = useContext(CmdInputContext);

  const action = useMemo(
    () => async () => {
      let prompt = gptPrompt?.prompt;
      const systemPrompt = gptPrompt?.systemPrompt;

      if (inputPrompt) {
        // ManualPrompt (i.e. override prompt from text field)
        if (useInput) {
          prompt = setPrompt(inputPrompt, systemPrompt);
          // InputPrompt (i.e. substitute input() from text field)
        } else if (gptPrompt?.prompt?.match(INPUT_REGEX)) {
          prompt = setPrompt(
            gptPrompt.prompt.replace(INPUT_REGEX, `${JSON.stringify(inputPrompt)}`),
            systemPrompt,
          );
        }
      }

      addGptOutputToNote(document, gptPrompt, highlight, prompt, systemPrompt);
    },
    [inputPrompt, highlight, document, gptPrompt, useInput],
  );

  return (
    <PaletteAction focused action={action} shortcut={`${getActionKey()}+enter`}>
      Prompt
    </PaletteAction>
  );
};
